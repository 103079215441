export function sendMaxEvent(eventName, eventData, extra) {
  try {
    const page = document.location.pathname;
    (window.maxAnalyticsEvents || []).push(
      new CustomEvent(eventName, {
        detail: { eventName, page, eventData, ...extra },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function sendPublicMaxEvent(eventName, eventData, extra) {
  try {
    const page = document.location.pathname;
    (window.maxAnalyticsPublicEvents || []).push(
      new CustomEvent(eventName, {
        detail: { eventName, page, eventData, ...extra },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export const sendMaxEventV2 = (event) => {
  try {
    (window.maxAnalyticsEventsV2 || []).push(
      new CustomEvent("maxAnalyticsEvent", {
        detail: {
          eventName: event.eventName,
          page: window.location.pathname,
          eventData: {
            ...event.eventData,
            source: "WEB"
          }
        }
      })
    );
  } catch (e) {
    console.error(e);
  }
}
