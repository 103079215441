const KEY_USER_TEMPLATE_CONTEXT_DATA= "reece-user-template-context-data";

export function getCacheUserData(): ?UserData {
    var userData = getSessionStorageUserData();
    return userData;
}

export function setCacheUserData(userData: ?UserData) {
    if (userData) {
        sessionStorage.setItem(KEY_USER_TEMPLATE_CONTEXT_DATA, JSON.stringify(userData));
    } else {
        sessionStorage.removeItem(KEY_USER_TEMPLATE_CONTEXT_DATA);
    }
}

function getSessionStorageUserData(): ?UserData {
    const dataStr = sessionStorage.getItem(KEY_USER_TEMPLATE_CONTEXT_DATA);
    try {
        return dataStr ? JSON.parse(dataStr) : null;
    } catch (e) {
        return null;
    }
}
