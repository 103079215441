const API_FETCH_USER_DATA = "/cms/frontend/service/user/permissions";

let lastUserDataFetchPromise = null;

export async function fetchUserData() {
    try {
        const fetchPromise = getUserDataFetchPromise();
        return await fetchPromise;
    } finally {
        lastUserDataFetchPromise = null;
    }
}

function getUserDataFetchPromise() {
    if (!lastUserDataFetchPromise) {
        const f = fetch(API_FETCH_USER_DATA, {
            credentials: "include"
        });
        lastUserDataFetchPromise = f.then(r => r.json());
    }
    return lastUserDataFetchPromise;
}
