import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-footer-payment-info-logo";

export class FooterPaymentInfoLogo extends HTMLElement {

    //  Don't pass javascript functions in config file due to security concerns
    _trustwave = {
        style: "cursor:pointer;",
        onclick: "javascript:window.open('https://sealserver.trustwave.com/cert.php?customerId=9a123617b9d54b50b512997f20b06173&amp;size=105x54&amp;style=', 'c_TW', 'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720'); return false;",
        oncontextmenu: "javascript:alert('Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'); return false;"
    };
    _ele;
    _logo;

    constructor(logo) {
        super();
        this._logo = logo;
    }

    connectedCallback() {

        this._ele = (
            <li>
                <img
                    class="footer-payment-info__logo-2x"
                    src={this._logo.src}
                    data-src={this._logo.src}
                    alt={this._logo.alt}
                    {...this._logo.title ? {title: this._logo.title} : {}}
                    {...this.isTrustwave() ? {onclick: this._trustwave.onclick} : {}}
                    {...this.isTrustwave() ? {oncontextmenu: this._trustwave.oncontextmenu} : {}}
                    {...this.isTrustwave() ? {style: this._trustwave.style} : {}}
                />
            </li>
        );

        this.appendChild(this._ele);
    }

    isTrustwave = function() {
        return this._logo.alt === "Trustwave";
    }
}

customElements.define(TAG, FooterPaymentInfoLogo);