import jsCookie from "js-cookie";
import { REECE_ACCOUNT_NUMBER_COOKIE_KEY } from "./local-user";

export function isUserDataValid(userData) {
    if (userData.isMaxUser) {
        const accountNumberInCookie = jsCookie.get(
            REECE_ACCOUNT_NUMBER_COOKIE_KEY
        );
        if (!accountNumberInCookie) {
            //no cookie found, invalidate cache and refetch
            return false;
        }
        if (!userData.activeAccount) {
            return false;
        }
        if (!userData.activeAccount.customerNumber) {
            return false;
        }
        if (
            userData.activeAccount.customerNumber.toString() !==
            accountNumberInCookie
        ) {
            return false;
        }
    }
    return true;
}
