import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "reece-cart-retail";

export class CartRetail extends HTMLElement {

    _ele;
    _triedScriptLoadedCount = 0;
    _cartItemCount = 0;

    constructor() {
        super();
    }

    connectedCallback() {

        this._ele = (
            <script src="/bathrooms/cart/share/resources/cart.js" data-template-script-re-eval="true"></script>
        );

        this.appendChild(this._ele);
        this._tryFetchRetailCartItemCount();
        this._listenToRetailCartItemUpdateEvent();
    }

    /* ----- private functions ---------- */

    /* This is necessary for client side scripts
    because the client side use body.append which cannot
    ganrantee the cart.js is loaded */
    _tryFetchRetailCartItemCount() {
        let _self = this;
        setTimeout(function () {
            _self._triedScriptLoadedCount++;
            var scriptLoaded = _self._fetchRetailCartItemCount();
            if (!scriptLoaded && _self._triedScriptLoadedCount <= 10) {
                _self._tryFetchRetailCartItemCount();
            }
        }, 1000);
    }

    _fetchRetailCartItemCount() {
        if (window.CART) {
            window.CART.fn.getCart(function (cart) {
                this._cartItemCount = cart.products.length;
                this._updateCartCount();
            });
            return true;
        } else {
            this._updateCartCount();
            return false;
        }
    }

    _listenToRetailCartItemUpdateEvent() {
        let _self = this;

        window.addEventListener('retail-cart__cart-items-updated', function (e) {
            _self._cartItemCount = e.detail.itemCount;
            _self._updateCartCount();
        });
    }

    _getCartHolder() {
        return document.getElementById('cart-count-holder');
    }

    _updateCartCount() {
        var ele = this._getCartHolder();
        if (ele) {
            ele.innerHTML = this._cartItemCount;
        }
    }

}

customElements.define(TAG, CartRetail);