import {setCacheComponentFooterConfig, getCacheComponentFooterConfig} from "./cache-component-footer-config";
import {fetchComponentFooterConfig} from "./fetch-component-footer-config";
import {reportError} from "../error-reporting";

import * as config from "../../../_config/footer/config.footer.data.au.default";

export async function getComponentFooterConfig() {
    try {
        let config = getCacheComponentFooterConfig();
        if (!config) {
            config = await fetchComponentFooterConfig();
            setCacheComponentFooterConfig(config);
        }
        return config;
    }
    catch(e) {
        reportError(e + "Component footer config unavailable");
        return config.default;
    }
}