const KEY_COMPONENT_FOOTER_CONFIG = "rg-footer-config";

export function getCacheComponentFooterConfig() {
    var config = getSessionStorageComponentConfig();
    return config;
}

export function setCacheComponentFooterConfig(config) {
    if (config) {
        sessionStorage.setItem(KEY_COMPONENT_FOOTER_CONFIG, JSON.stringify(config));
    } else {
        sessionStorage.removeItem(KEY_COMPONENT_FOOTER_CONFIG);
    }
}

function getSessionStorageComponentConfig() {
    const dataStr = sessionStorage.getItem(KEY_COMPONENT_FOOTER_CONFIG);
    try {
        return dataStr ? JSON.parse(dataStr) : null;
    } catch (e) {
        return null;
    }
}
