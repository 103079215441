import "./footer-payment-info.scss";
import { FooterPaymentInfoLogo } from "../footer-payment-info-logo/footer-payment-info-logo";

import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-footer-payment-info";

export class FooterPaymentInfo extends HTMLElement {

    _ele;
    _paymentInfo;

    constructor(paymentInfo) {
        super();
        this._paymentInfo = paymentInfo;
    }

    connectedCallback() {
        this._ele = (
            <div class="page-footer__payment-info">
                <div class="footer-payment-info">
                    <ul class="footer-payment-info__left">
                        {this._paymentInfo.leftLogos.map((logo) => {
                            return (
                                new FooterPaymentInfoLogo(logo)
                            )
                        })}
                    </ul>
                    <ul class="footer-payment-info__right">
                        {this._paymentInfo.rightLogos.map((logo) => {
                            return (
                                new FooterPaymentInfoLogo(logo)
                            )
                        })}
                    </ul>
                </div>
            </div>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, FooterPaymentInfo);