import "./footer.scss";
import { FooterMain } from "./footer-main/footer-main";
import { FooterPaymentInfo } from "./footer-payment-info/footer-payment-info";
import { CartScripts } from "./cart-scripts/cart-scripts";
import { getComponentFooterConfig } from "../scripts/component-footer-config";
import { getUserData } from "../scripts/user-data";

// This is in use
import { h } from "../_common/jsx-dom.svg.js";

const TAG = "rg-footer";

class Footer extends HTMLElement {

    _ele;

    constructor() {
        super();
    }

    connectedCallback() {

        (async() => {
            const data = await getComponentFooterConfig();
            const userData = await getUserData();

            let cartScripts = new CartScripts(userData, data.features.retailCart);
            const footerPaymentInfo = new FooterPaymentInfo(data.footer.paymentInfo);
            const footerMain = new FooterMain(data.footer);

            this._ele = (
                <footer id="page-footer" class={data.theme + " page-footer-wrapper"}>
                    {footerPaymentInfo}
                    {footerMain}
                    {cartScripts}
                </footer>
            );

            this.appendChild(this._ele);

        })();
    }
}

customElements.define(TAG, Footer);