import { CartMax } from "./cart-max-scripts"
import { CartRetail } from "./cart-retail-scripts";

import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "reece-cart-scripts";

export class CartScripts extends HTMLElement {

    _ele;
    _retailCart;
    _userData;

    constructor(userData, retailCart) {
        super();
        this._userData = userData;
        this._retailCart = retailCart;
    }

    connectedCallback() {

        let cart;
        if(this._userData.isMaxUser) {
            cart = new CartMax();
        }
        else if(this._retailCart) {
            cart = new CartRetail();
        }

        this._ele = (
            <div class="cart-scripts-holder" data-reece-mustache-template-id="cart-scripts">
                {cart}
            </div>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, CartScripts);