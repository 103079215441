import jsCookie from "js-cookie";

export const REECE_ACCOUNT_NUMBER_COOKIE_KEY = "reece-account-number";
export const REECE_USER_PROFILE_COOKIE_KEY = "reece-user-profile";

export const getCookieCustomerNumber = () => {
   const accountNumberCookieValue = jsCookie.get(REECE_ACCOUNT_NUMBER_COOKIE_KEY);

   return accountNumberCookieValue || "";
};

export const getCookieUserId = () => {
   const isJson = true;
   const userProfileCookie = jsCookie.get(REECE_USER_PROFILE_COOKIE_KEY, isJson);
   const userProfile = JSON.parse(userProfileCookie)

   return userProfile ? userProfile.id : "";
};
