import { h } from "../../_common/jsx-dom.svg.js";
import { sendMaxEventV2 } from "../../scripts/max-analytics/index.js";

const TAG = "rg-footer-sitemap";

export class FooterSitemap extends HTMLElement {

    _ele;
    _data;

    constructor(data) {
        super();
        this._data = data;
    }

    connectedCallback() {
        this._ele = (
            <nav class="footer-sitemap__navlist">
                <h5 class="footer-sitemap__navlist-header">{this._data.header}</h5>
                <ul class="footer-sitemap__navlist-link-group">
                    {this._data.links.map((link) => {
                        let analyticFunction;
                        if (link.analytics) {
                            analyticFunction = () => {
                                sendMaxEventV2({
                                    eventName: link.analytics.eventName,
                                    eventData: link.analytics.eventData
                                });
                            }
                        }
                        
                        return (
                            <li>
                                <a
                                    class="footer-sitemap__navlist-link"
                                    href={link.href}
                                    {...link.analytics ? {onClick: analyticFunction} : {}}
                                    {...link.target ? {target: link.target} : {}}
                                >
                                    {link.iconClass && (
                                        <i class={link.iconClass}></i>
                                    )} {link.text}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, FooterSitemap);
