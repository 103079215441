import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-footer-legal-links";

export class FooterLegalLinks extends HTMLElement {

    _ele;
    _links;

    constructor(links) {
        super();
        this._links = links;
    }

    connectedCallback() {
        this._ele = (
            <ul class="footer-sitemap__legal-link-group ">
                {this._links.map((link) => {
                    return (
                        <li><a href={link.href} class="footer-sitemap__legal-link">{link.text}</a></li>
                    )
                })}
            </ul>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, FooterLegalLinks);
