const API_FETCH_COMPONENT_CONFIG = "/components/footer/rg-footer.config.json";

let lastComponentConfigFetchPromise = null;

export async function fetchComponentFooterConfig() {
    try {
        const fetchPromise = getComponentConfigFetchPromise();
        return await fetchPromise;
    } finally {
        lastComponentConfigFetchPromise = null;
    }
}

function getComponentConfigFetchPromise() {
    if (!lastComponentConfigFetchPromise) {
        const f = fetch(API_FETCH_COMPONENT_CONFIG, {
            credentials: "include"
        });
        lastComponentConfigFetchPromise = f.then(r => r.json());
    }
    return lastComponentConfigFetchPromise;
}
