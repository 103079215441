import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-footer-seo-copy";

export class FooterSEOCopy extends HTMLElement {

    _ele;
    _seoCopy

    constructor(seoCopy) {
        super();
        this._seoCopy = seoCopy;
    }

    connectedCallback() {
        this._ele = (
            <div>
                {this._seoCopy}
            </div>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, FooterSEOCopy);