import { h } from "../../_common/jsx-dom.svg.js";
import { getCookieCustomerNumber, getCookieUserId } from "../../scripts/user-data/local-user";

const TAG = "reece-cart-max";
const newMiniCartListTypes = ["QUOTING_TOOL", "CUSTOMER_INVOICE"]
/* eslint-disable */

export class CartMax extends HTMLElement {

    _ele;
    _maxCartItemCount = 0;

    constructor() {
        super();
    }

    connectedCallback() {
        /* eslint-disable */
        const miniCartScript = this._shouldUseNewMiniCart()
            ? "/max/mini-cart/static/js/max-mini-cart.nocache.js"
            : "/max/cart/static/js/max-cart-no-cache.js";

        this._ele = (
            <div id="global-cart">
                <script src={miniCartScript} async defer data-template-script-re-eval="true"></script>
                <div id="shopping-cart-widget"></div>
            </div>
        );

        this.appendChild(this._ele);

        this._listenToMaxCartWidgetItemUpdateEvent();
        this._listenToClientSideRenderingDomChanged();
    }

    _shouldUseNewMiniCart() {
        const listType = this._getListTypeFromQueryParameters() || this._getLastListFromLocalStorage() || ""

        return !!(listType && newMiniCartListTypes.includes(listType.toUpperCase()));
    }


    _getListTypeFromQueryParameters() {
        const params = (new URL(document.location)).searchParams;

        return params.get("listType");
    }

    _getLastListFromLocalStorage() {
        const lastListFromLocalStorage = window.localStorage.getItem(`lastListInSession_${getCookieUserId()}_${getCookieCustomerNumber()}`);
        const lastList = JSON.parse(lastListFromLocalStorage)

        return lastList ? lastList.listType : null;
    }

    _listenToMaxCartWidgetItemUpdateEvent() {
        let _self = this;
        window.addEventListener('max-widget-cart__cart-items-updated', function (e) {
            /* store it in closure for latter use */
            _self._maxCartItemCount = e.detail.itemCount;
            /* update count in icon */
            _self._updateMaxProductWidgetCount();
        });
    }

    _initCartIconForMax() {
        this._openCartWhenIconClicked();
        this._updateMaxProductWidgetCount();
    }

    _listenToClientSideRenderingDomChanged() {
        var interval =  setInterval(() => {
            var clientRenderDom = this._getCartClientRenderDom();
            if (clientRenderDom) {
                this._initCartIconForMax()
                clearInterval(interval)
            }
         }, 200)

        // Clear the interval after 10 seconds
        setTimeout(() => {
            clearInterval(interval)
        }, 10000)
    }

    _openCartWhenIconClicked() {
        var eleCartIcon = this._getCartIcon();
        if (eleCartIcon) {
            eleCartIcon.addEventListener('click', this._dispatchOpenCartEvent);
        }
    }

    _dispatchOpenCartEvent(e) {
        e.preventDefault();
        e.stopPropagation();
        window.dispatchEvent(new CustomEvent('max-widget-cart__expand'));
    }

    _getCartCount() {
        return document.getElementById('cart-count-holder');
    }

    _getCartIcon() {
        return document.getElementById('cart-icon');
    }

    _getCartIconSvg() {
        return document.getElementById('max-cart-icon-svg');
    }

    _getCartClientRenderDom() {
        return document.querySelector('[data-reece-mustache-template-id="cart-nav"]');
    }

    _updateMaxProductWidgetCount() {
        var cartCountEle = this._getCartCount();
        var cartIconSvgEle = this._getCartIconSvg();

        if (this._maxCartItemCount === 0) {
            cartIconSvgEle.classList.add("empty-cart");
            cartCountEle.classList.add("empty-cart");
        } else {
            cartIconSvgEle.classList.remove("empty-cart");
            cartCountEle.classList.remove("empty-cart");
        }
        if (cartCountEle) {
            cartCountEle.innerHTML = this._maxCartItemCount;
        }
    }
}

customElements.define(TAG, CartMax);
