import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-footer-back-to-top";

class FooterBackToTop extends HTMLElement {

    _ele;

    constructor() {
        super();
    }

    connectedCallback() {
        this._ele = (
            <button id="footer-btt" class="footer-sitemap__back-to-top" type="button" onClick={this._backToTop}>
                <i class="zmdi zmdi-chevron-up footer-sitemap__back-to-top-icon"></i>
            </button>
        );
        this.appendChild(this._ele);
    }

    _backToTop() {
        window.scrollTo(0, 0);
    }
}

customElements.define(TAG, FooterBackToTop);