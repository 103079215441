import { fetchUserData } from "./fetch-user-data";
import { getCacheUserData, setCacheUserData } from "./cache-user-data";
import { isUserDataValid } from "./validate-user-data";
import { getAnonymousUser } from "./anonymous-user-data";
import { reportError } from "../error-reporting";

export async function getUserData() {
    try {
        let userData = getCacheUserData();
        const isValid = userData && isUserDataValid(userData);
        if (!userData || !isValid) {
            userData = await fetchUserData();
            setCacheUserData(userData);
        }
        return userData;
    }
    catch(e) {
        reportError("User permissions service unavailable");
        return getAnonymousUser();
    }
}
