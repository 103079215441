import "./footer-main.scss";
import "../footer-back-to-top/footer-back-to-top";
import { FooterSEOCopy } from "../footer-seo-copy/footer-seo-copy";
import { FooterAcknowledgement } from "../footer-acknowledgement/footer-acknowledgement";
import {FooterLegalLinks} from "../footer-legal-links/footer-legal-links";
import {FooterSitemap} from "../footer-sitemap/footer-sitemap";

import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-footer-main";

export class FooterMain extends HTMLElement {

    _ele;
    _data;

    constructor(data) {
        super();
        this._data = data;
    }

    connectedCallback() {

        const footerSEOCopy = new FooterSEOCopy(this._data.seo);
        const footerAcknowledgement = new FooterAcknowledgement(this._data.acknowledgementAU);
        const footerLegalLinks = new FooterLegalLinks(this._data.legalLinks);

        this._ele = (
            <div class="page-footer__main">
                <div class="footer-sitemap">
                    <rg-footer-back-to-top></rg-footer-back-to-top>
                    <div class="footer-sitemap__top">
                        <img class="logo--footer" src={this._data.logo.src} data-src={this._data.logo.src} alt={this._data.logo.alt} />
                    </div>
                    <div class="footer-sitemap__mid">
                        <div class="footer-sitemap__navgroup">
                            {this._data.sitemaps.map((sitemap) => {
                                return new FooterSitemap(sitemap)
                            })}
                        </div>
                        <div class="footer-sitemap__seo-text">
                            {footerSEOCopy}
                            {footerAcknowledgement}
                        </div>
                    </div>
                    <div class="footer-sitemap__btm">
                        <div class="footer-sitemap__legal-text">
                            Copyright &copy; {this._getCurrentYear()}, {this._data.copyrightText}.
                        </div>
                        {footerLegalLinks}
                    </div>
                </div>
            </div>
        );

        this.appendChild(this._ele);
    }

    _getCurrentYear() {
        const d = new Date();
        return d.getFullYear();
    }
}

customElements.define(TAG, FooterMain);